

.topbar{
    width: 100%;
    height: 70px;
    background-color: #353e4d;
    color: white;
    position: fixed;
    top: 0;
    z-index: 3;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left{
            display: flex;
            align-items: center;
            .logo{
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;
            }

            .itemContainer{
                
                display: flex;
                align-items: center;
                margin-left: 30px;


                .icon{
                    font-size: 18px;
                    margin-right: 5px;
                }
                
                .link{
                    font-size: 15px;
                    font-weight: 500;
                    color: white;
                    font-style:none;
                    cursor: pointer;
                }
            }
        }
        .right{
            .triple{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                transition: all 0.7s ease;
                span{
                    width: 100%;
                    height: 3px;
                    background-color: white;
                    
                }
            }
        }

    }
    &.active{
        background-color: white;
        color: #353e4d;
        transition: all 0.7s ease;

        .right{
            .triple{
                span{
                    background-color: #353e4d;
                }
            }
        }
    }
}