.app{
    height: 100vh;
    background-color: #353e4d;

    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;


        > *{
            width: 100vw;
            height: calc(100vh - 70px);
            display: grid;
        }
    }
}