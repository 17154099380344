.menu{
    width: 280px;
    height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    right: -280px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.7s ease;
    
    &.active {
        right: 0;
    }

    .icon{
        margin: 0;
        padding: 30px;
        list-style: none;
        font-size: 130px;
        color: #353e4d;
    }
}