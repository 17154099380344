
.projects{
    background-color: #353e4d;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.7s ease;

    &.active{
        .slider1{
            opacity: 0.1;
        }
        .slider2{
            opacity: 0.1;
        }
        .slider3{
            opacity: 0.1;
        }
    }
    .slider1{
        height: 350px;
        display: grid;
        position: flex;
        justify-content: center;
        left:0;
        padding: 20px;
        overflow: hidden;

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;


            .item{
                width: 800px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;


                .left{
                    flex: 4;
                    height: 80%;
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .logo{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #353e4d;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            .icon{
                                width: 25px;
                            }
                        }
                        h2{
                            font-size: 20px;
                        }
                        p{
                            font-size: 13px;

                        }
                        a{
                            font-size: 13px;
                            font-weight: 600;
                            color: #EBCB8B;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width: 90%;
                    }
                }
            }
        }
    }

    .slider2{
        height: 350px;
        display: grid;
        position: flex;
        justify-content: center;
        left:0;
        padding: 20px;
        overflow: hidden;

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;


            .item{
                width: 800px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;


                .left{
                    flex: 4;
                    height: 80%;
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .logo{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #353e4d;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            .icon{
                                width: 25px;
                            }
                        }
                        h2{
                            font-size: 20px;
                        }
                        p{
                            font-size: 13px;

                        }
                        a{
                            font-size: 13px;
                            font-weight: 600;
                            color: #EBCB8B;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width: 400px;
                        transform: rotate(10deg);
                    }
                }
            }
        }
    }
    .slider3{
        height: 350px;
        display: grid;
        position: flex;
        justify-content: center;
        left:0;
        padding: 20px;
        overflow: hidden;

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;


            .item{
                width: 800px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;


                .left{
                    flex: 4;
                    height: 80%;
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .logo{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #353e4d;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            .icon{
                                width: 25px;
                            }
                        }
                        h2{
                            font-size: 20px;
                        }
                        p{
                            font-size: 13px;

                        }
                        a{
                            font-size: 13px;
                            font-weight: 600;
                            color: #EBCB8B;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width: 90%;
                    }
                }
            }
        }
    }

    
}